<template>
  <v-container fluid>
    <v-app-bar color="" app fixed clippedLeft v-if="hideMenu">
      <v-app-bar-nav-icon
        @click.stop="drawer.mini = !drawer.mini"
      ></v-app-bar-nav-icon>
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-list dense>
              <v-list-item class="px-2" v-bind="attrs" v-on="on">
                <v-list-item-avatar>
                  <v-img src="https://static.divrt.co/images/img.jpg"></v-img>
                </v-list-item-avatar>

                <v-list-item-title
                  ><span class="font-weight-bold text-overline">{{
                    userName
                  }}</span></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </template>
          <v-list>
            <v-list-item>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    tile
                    block
                    class="elevation-0 mr-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="logout"
                    ><v-icon small left>logout</v-icon>Logout</v-btn
                  >
                </template>
                <span>Logout</span>
              </v-tooltip>
            </v-list-item>
            <v-list-item>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    tile
                    block
                    class="elevation-0 mr-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="resetPassword"
                    ><v-icon small left></v-icon>Reset Password</v-btn
                  >
                </template>
                <span>Reset Password</span>
              </v-tooltip>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <ThemeChanger /> -->
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer
        v-if="hideMenu"
        :clipped="true"
        :fixed="true"
        :permanent="true"
        :mini-variant="drawer.mini"
        v-model="drawer.open"
        app
      >
        <v-list dense>
          <!-- <v-list-item class="px-2">
                <v-list-item-avatar>
                  <v-img src="assets/no_bkgnd_no_box_Logo"></v-img>
                </v-list-item-avatar>
              </v-list-item> -->
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img src="https://static.divrt.co/images/img.jpg"></v-img>
            </v-list-item-avatar>

            <v-list-item-title
              ><span class="font-weight-bold text-overline">{{
                userName
              }}</span></v-list-item-title
            >
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list dense>
          <v-list-item
            active-class="primary--text"
            v-for="(item, index) in filteredNavItems"
            :key="index"
            :to="item.path"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-html="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    <v-row no-gutters>
      <v-col cols="12">
        <keep-alive>
          <router-view />
        </keep-alive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NavView",
  components: {},
  data(){
    return{
      toolBarMenu: false,
      drawer: {
        open: false,
        clipped: false,
        fixed: false,
        permanent: true,
        mini: true,
      },
      navbarItems: [
        {
          path: "/allzones",
          title: "All Zones",
          icon: "holiday_village",
        },
        {
          path: "/zone/dashboard",
          title: "Live",
          icon: "trending_up",
        },
        {
          path: "/zone/reports",
          title: "Reports",
          icon: "table_view",
        },
        {
          path: "/zone/zone",
          title: "Zone",
          icon: "local_parking",
        },
        {
          path: "/zone/history",
          title: "Analytics",
          icon: "analytics",
        },
        {
          path: "/zone/allowedplates",
          title: "Allowed Plates",
          icon: "garage",
        },
        {
          path: "/zones",
          title: "Zones List",
          icon: "public",
        },
        {
          path: "/accounts",
          title: "Accounts",
          icon: "manage_accounts",
        },
        {
          path: "/lpr",
          title: "LPR",
          icon: "camera_outdoor",
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      userName: "authStore/getterUserName",
      ltype:"authStore/getterUserLType"
    }),
    hideMenu() {
      return (
        this.$route.name !== "LoginView" &&
        this.$route.name !== "ForgotPasswordView"
      );
    },
    filteredNavItems(){
      let ltype = this.ltype;
      var items;//=[];
      switch (ltype){
        case 'divrt':
          items = ["All Zones", "Live", "Reports", "Zone", "Analytics", "Allowed Plates", "Zones List", "Accounts", "LPR"];
        break;
        default:
           items = ["Live", "Reports", "Zone", "Analytics"];
        break;
      }
      return this.navbarItems.filter(a => items.includes(a.title))
    }
    
  },
  watch:{
  },
  mounted(){
  },
  methods:{
     logout() {
      this.$store.commit("authStore/SET_USER", {});
      this.$store.commit("authStore/SET_DIVRT_TOKEN", "");
      this.$store.commit("pinaStore/RESET_STATE");
      localStorage.removeItem('vuex') 
      localStorage.setItem("divrtToken", "");
      this.$router.replace({ path: "login" });
    },
    resetPassword(){
      this.$router.push({path:"/resetpassword"})
    }
  }
};
</script>
