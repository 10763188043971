var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.hideMenu)?_c('v-app-bar',{attrs:{"color":"","app":"","fixed":"","clippedLeft":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer.mini = !_vm.drawer.mini}}}),_c('v-toolbar-title'),_c('v-spacer'),_c('v-toolbar-items',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',_vm._g(_vm._b({staticClass:"px-2"},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":"https://static.divrt.co/images/img.jpg"}})],1),_c('v-list-item-title',[_c('span',{staticClass:"font-weight-bold text-overline"},[_vm._v(_vm._s(_vm.userName))])])],1)],1)]}}],null,false,1067451208)},[_c('v-list',[_c('v-list-item',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"elevation-0 mr-1",attrs:{"small":"","tile":"","block":""},on:{"click":_vm.logout}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("logout")]),_vm._v("Logout")],1)]}}],null,false,97332781)},[_c('span',[_vm._v("Logout")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"elevation-0 mr-1",attrs:{"small":"","tile":"","block":""},on:{"click":_vm.resetPassword}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}}),_vm._v("Reset Password")],1)]}}],null,false,3839925221)},[_c('span',[_vm._v("Reset Password")])])],1)],1)],1)],1)],1):_vm._e(),(_vm.hideMenu)?_c('v-navigation-drawer',{attrs:{"clipped":true,"fixed":true,"permanent":true,"mini-variant":_vm.drawer.mini,"app":""},model:{value:(_vm.drawer.open),callback:function ($$v) {_vm.$set(_vm.drawer, "open", $$v)},expression:"drawer.open"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":"https://static.divrt.co/images/img.jpg"}})],1),_c('v-list-item-title',[_c('span',{staticClass:"font-weight-bold text-overline"},[_vm._v(_vm._s(_vm.userName))])])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.filteredNavItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"active-class":"primary--text","to":item.path}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.title)}})],1)],1)}),1)],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('keep-alive',[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }